import { Amplify } from 'aws-amplify';
import {
  AWS_COGNITO_USER_POOL_CLIENT_ID,
  AWS_COGNITO_USER_POOL_ID,
  AWS_COGNITO_USER_POOL_ENDPOINT,
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_S3_BUCKET_NAME,
} from './env';

export const amplifyConfigure = () => {
  Amplify.configure(
    {
      Auth: {
        Cognito: {
          userPoolId: AWS_COGNITO_USER_POOL_ID,
          userPoolClientId: AWS_COGNITO_USER_POOL_CLIENT_ID,
          userPoolEndpoint: AWS_COGNITO_USER_POOL_ENDPOINT,
          identityPoolId: AWS_IDENTITY_POOL_ID,
          allowGuestAccess: true,
        },
      },
      Storage: {
        S3: {
          bucket: AWS_S3_BUCKET_NAME,
          region: AWS_REGION,
        },
      },
    },
    { ssr: true },
  );
};
